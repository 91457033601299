<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Body-->
        <div class="row justify-content-center px-8 px-lg-8">
          <div class="col-xl-12">
            <!--begin: Wizard Form-->
            <Form
              :form="form"
              route="village-business"
              purpose="add"
              title="Tambah Tempat Usaha"
            />
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/village-business/Form.vue";
import moment from "moment";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        business_name: "",
        regency_name: "Karawang",
        longitude: "",
        latitude: "",
        display_address_map: "",
        commodity_name: "",
        village_business_category_id: "",
        village_business_category_name: "",
        address: "",
        business_place_status: "",
        product: "",
        capital: "",
        turnover: ""
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Tempat Usaha", route: "/village-business/list" },
      { title: "Tambah Tempat Usaha" },
    ]);
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
